import React, {useState} from "react";
import "./../styles/createGame.css";

import { useNavigate } from "react-router-dom";
import { getGraphQl } from "./../hooks/ApiCalls";

import { Navbar } from "../components/Navbar";
import { SelectVotingSystem } from "../components/SelectVotingSystem";

function CreateGame () {

    const navigate = useNavigate();

    const [values, setValues] = useState({
        name:"",
        votingTypeId: "",
      });

    let graphQuery= `
        mutation {
            createGameRoom(GameRoom:{name:"${values.name}",votingTypeId:"${values.votingTypeId}"}) {
            gameRoomGuid
            }
        }
    `;

    function handleChange(evt) {
        const { target } = evt;
        const { name, value } = target;
    
        const newValues = {
          ...values,
          [name]: value,
        };
        setValues(newValues);
    }

    const handleCreateGame = async (evt) => {
        evt.preventDefault();
        const {data:{data:{createGameRoom}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});
        
        if (createGameRoom.gameRoomGuid) {
            navigate(`/game/${createGameRoom.gameRoomGuid}`);
        }
    };

    return (
        <React.Fragment>
            <Navbar/>
            <div className="create-game-page-container">
                <form className="create-game-form-container">
                    <label for="name">Game's name</label>
                    <input className="create-game-form-input" name= "name" type="text" id="name" value={values.name} placeholder="Game's name" onChange={handleChange} required/>

                    <label for="votingTypeId">Voting system</label>
                    <SelectVotingSystem
                        defaultValue="149b4749-37aa-4acb-976d-1fd2d5a3d270"
                        onChange={handleChange}
                        name="votingTypeId"
                    />
                    <button className="create-game-btn" onClick={handleCreateGame}>Create game</button>
                </form>
            </div>
        </React.Fragment>
    )

}

export {CreateGame};