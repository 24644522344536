import React from "react";
import "./../styles/votingCard.css";

function VotingCard ({cardValue, selected, id, handleUserVote}) {

    const userVote = () => {
        handleUserVote(id);
    }

    return(
        <div className={`scrum-poker-app-player-vote-card ${selected ? "scrum-poker-app-player-vote-card-selected" : "otherclass"} `}>
            <div className={`scrum-poker-app-player-inner-vote-card ${selected ? "scrum-poker-app-player-inner-vote-card-selected" : "otherclass"} `} onClick={userVote}>
                <label className="scrum-poker-app-player-vote-card-value">{cardValue}</label>
            </div>
        </div>
    );
}

export {VotingCard};