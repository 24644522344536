import React from "react";
import "./styles/root.css";

import { createHashRouter, RouterProvider } from "react-router-dom";
import { ToogleProvider } from "./hooks/ToogleContext";
import { Home } from "./pages/Home";
import { CreateGame } from "./pages/CreateGame";
import { ScrumPokerApp } from "./pages/ScrumPokerApp";

const router = createHashRouter ([
  {
    path: "/",
    element: <Home/>,
  },
  {
    path: "/new-game",
    element: <CreateGame/>,
  },
  {
    path: "/game/:gameRoomGuid",
    element: <ScrumPokerApp/>,
  },

]);

function App() {

  return (
    <ToogleProvider>
      <RouterProvider router = {router}/>
    </ToogleProvider>
   
  );
}

export default App;
