import React, {useEffect,useState} from "react";

import {getGraphQl} from "./../hooks/ApiCalls";

function SelectVotingSystem ({defaultValue, onChange, name}) {

    const [selectValues, setselectValues] = useState([]);

    let graphQuery = `
        query {
            VotingSystem {
            votingTypeId
            name
            enabled
            }
        }
    `;

    useEffect(() => {
        (async () => {
            const {data:{data:{VotingSystem}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});
            setselectValues(VotingSystem);
        })();

      }, []);

    return (
        <React.Fragment>
                <select onChange={onChange} name={name} className="create-game-form-input">
                    {selectValues.map(({votingTypeId,name}) => {
                        return <option selected={votingTypeId === defaultValue} value={votingTypeId}>{name}</option>
                    })}
                </select>
        </React.Fragment>
    );

}

export {SelectVotingSystem};