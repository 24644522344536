import { useState } from "react";

export default function useGameUserDetails(){

    const getGameUserDetails = () => {
        const gameUserDetailString = sessionStorage.getItem("scrumpoker.agilexcellence.io:userGameDetails");
        const gameUserDetail = JSON.parse(gameUserDetailString);
        return gameUserDetail;
    };

    const [gameUserDetails, setgameUserDetails] = useState(getGameUserDetails());

    const saveGameUserDetails = GameUserDetails => {
        localStorage.setItem('scrumpoker.agilexcellence.io:userGameDetails', JSON.stringify({user:GameUserDetails.user}));
        sessionStorage.setItem('scrumpoker.agilexcellence.io:userGameDetails', JSON.stringify(GameUserDetails));
        setgameUserDetails(GameUserDetails);
        window.location.reload(true);
    };

    return {
        setgameUserDetails: saveGameUserDetails,gameUserDetails
    }
}
