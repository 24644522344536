import React,{useEffect,useCallback} from "react";
import  {createPortal}  from "react-dom";
import "../styles/copiedModal.css";

import closeIcon from "./../imgs/ActionsIcons/close.svg";

function CopiedModal ({onCloseCopiedModal}) {

      const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
          onCloseCopiedModal();
        }
      }, []);
    
      useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
      }, [escFunction]);

    return createPortal(
          <div className="ModalBackground-copied">
              <div className="copied-modal-main-container">
                <div className="copied-modal-inner-container">
                    <div className="copied-modal-close-container">
                        <div></div>
                        <img src={closeIcon} className="user-detail-close-icon" onClick={onCloseCopiedModal}/>
                    </div>
                    <h1 className="copied-modal-text">Game information copied successfuly! 🥳</h1>
                    <button className="copied-modal-btn" onClick={onCloseCopiedModal}>Let's continue</button>
                </div>
              </div>
            </div>
            ,
            document.getElementById("modal")
    );
}

export {CopiedModal};