import React, {useState,useEffect} from "react";
import "./../styles/scrumPokerApp.css";

import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getGraphQl } from "./../hooks/ApiCalls";

import useGameUserDetails from "./../hooks/useGameUserDetails";
import useCopyToClipboard from "./../hooks/useCopyToClipboard.tsx";

import { VotingCard } from "../components/VotingCard";
import { PlayerInfo } from "../components/PlayerInfo";
import { EditPlayerInfo } from "../components/EditPlayerInfo";
import { RenewSession } from "../components/RenewSession";
import { CopiedModal } from "../components/CopiedModal.js";

import logo from "./../imgs/Brand/Isotipo-Principal.png";
import cardLogo from "./../imgs/Brand/Isotipo-Blanco.png";
import addPlayerIcon from "./../imgs/ActionsIcons/add-profile.svg";

function ScrumPokerApp () {

    const navigate = useNavigate();

    const {gameUserDetails, setgameUserDetails} = useGameUserDetails();
    let {gameRoomGuid} = useParams();

    const [inputText, setInputText] = useState(window.location.href);
    const [copyToClipboard, copyResult] = useCopyToClipboard();

    const [openPlayerInitialInfo,setopenPlayerInitialInfo] = useState(false);
    const [openPlayerEditInfo,setopenPlayerEditInfo] = useState(false);
    const [openRenewSession, setopenRenewSession] = useState(false);
    const [openCopiedModal, setopenCopiedModal] = useState(false);

    const [gameInfo, setgameInfo] = useState({name:""});
    const [userSessionInfo,setuserSessionInfo] = useState({name:gameUserDetails?.user,gameSessionId: gameUserDetails?.gameSessionsId});
    const [userVotingCards,setuserVotingCards] = useState([{value:0,selected:false},{value:1,selected:false},{value:2,selected:false},{value:3,selected:false},{value:5,selected:false},{value:8,selected:false},{value:13,selected:false},{value:21,selected:false},{value:34,selected:false},{value:"?",selected:false},{value:"☕",selected:false}]);
    const [revealCards,setrevealCards] = useState(false);
    
    const [sessionUser,setsessionUser] = useState([{user:"",vote:"",selected:false}]);
    const [leftUser,setleftUser] = useState([]);
    const [rightUser,setrightUser] = useState([]);
    const [toptUser,settoptUser] = useState([]);
    const [bottomUser,setbottomUser] = useState([]);

    const handleCloseInitialPlayerInfo = () => {
        setopenPlayerInitialInfo(false);
    }

    const handleOpenPlayerEditInfo = () => {
        setopenPlayerEditInfo(true);
    };

    const handleCloseEditPlayerInfo = () => {
        setopenPlayerEditInfo(false);
    };

    const handleClosePlayerRenewSession = () => {
        setopenRenewSession(false);
    };

    const handleCloseCopiedModal = () => {
        setopenCopiedModal(false);
    };

    const handleRevealCards = () => {
        setrevealCards(!revealCards);
    };

    const handleUserVote = async (evt) => {
        let newValues = [];
        let isOptSelected = false;
        let valueSelected = "";
        
        if ( userVotingCards[evt].selected !=true) {
            userVotingCards.forEach(cardValue => {
                if(cardValue.selected) {
                    cardValue.selected =false;
                }
                newValues.push(cardValue);
            });
    
            newValues[evt].selected =true;
            isOptSelected = true;
            valueSelected = newValues[evt].value;
            setuserVotingCards(newValues);

        } else {
            newValues = [...userVotingCards];
            newValues[evt].selected = false;
            isOptSelected = false;
            valueSelected = "";
            setuserVotingCards(newValues);
        }

        let graphQueryUpdateMySession = `
            mutation {
                updateGameSession(GameSession:{gameRoomGuid:"${gameRoomGuid}", gameSessionsId:"${userSessionInfo.gameSessionId}", selected:${isOptSelected}, vote: "${valueSelected}"}) {
                success
                }
            }
        `;
        
        const {data:{data:{updateGameSession}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQueryUpdateMySession});
    };

    useEffect(() => {
        const top = [];
        const right = [];
        const left = [];
        const bottom = [];

        sessionUser.forEach((element,index) => {
            if(sessionUser.length == 1) { return bottom.push(element)}

            if(index%2 == 0) {
                if(index == 2 || index == 6) { right.push(element)}
                else {top.push(element);}
            } 
            else {
                if(index == 3 || index == 7) { left.push(element)}
                else {bottom.push(element);}
            }
        });

        settoptUser(top);
        setbottomUser(bottom);
        setleftUser(left);
        setrightUser(right);

    }, [sessionUser]);

    const handleInvitePlayers = async () => {
        try {
            const isCopied = copyToClipboard(inputText);

            if (isCopied) {
                setopenCopiedModal(true);
            }
           
          } catch (error) {
            console.error(error.message);
          }
    };

    useEffect(() => {
        let graphQuerySessions = `
            query {
                GetGameSessionsbyRoomId (GameSession:{gameRoomGuid:"${gameRoomGuid}"}) {
                user
                selected
                vote
                }
            }
        `;

        (async () => {
            const {data:{data:{GetGameSessionsbyRoomId}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuerySessions});
            setsessionUser(GetGameSessionsbyRoomId);
        })();

    }, []);

    useEffect(() => {
        let graphQuery = `
            query {
                GetGameRoombyId(GameRoom:{gameRoomGuid:"${gameRoomGuid}"}) {
                roomId
                votingTypeId
                name
                enabled
                }
            }
        `;
        
        (async () => {
            const {data:{data:{GetGameRoombyId}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});
            setgameInfo(GetGameRoombyId);
            document.title = `${GetGameRoombyId?.name} | Agilexcellence`;

            if (!gameUserDetails){
                setopenPlayerInitialInfo(true); 
            }

        })();

    }, []);

    return (
        <React.Fragment>
            <nav className="scrum-poker-app-nav-container">
                <div className="scrum-poker-app-nav-inner-container">
                    <div className="scrum-poker-app-nav-left-container">
                        <img src={logo} className="scrum-poker-app-nav-logo" alt="agilexcellence isotipo image" onClick ={() => navigate("/")}/>
                        <h1 className="scrum-poker-app-nav-game-name">{gameInfo?.name}</h1>
                    </div>
                    <div className="scrum-poker-app-nav-right-container">
                        <h2 className="scrum-poker-app-nav-player-name" onClick={handleOpenPlayerEditInfo}>{userSessionInfo.name}</h2>
                        <button className="scrum-poker-app-nav-invite-player-btn" onClick={handleInvitePlayers}><img src={addPlayerIcon} className="scrum-poker-app-invite-player-icon" alt="add player icon"/>Invite players</button>
                    </div>
                </div>
            </nav>

            <div className="scrum-poker-app-container">
                <div className="scrum-poker-app-inner-container">

                    <div className="scrum-poker-app-players-container">

                        {   
                            sessionUser.length == 1 ?
                                <div className="scrum-poker-app-lonely-msg">
                                    <h2 className="scrum-poker-app-lonely-txt">Feeling lonely? 😴</h2>
                                    <h2 className="scrum-poker-app-lonely-btn" onClick={handleInvitePlayers}>Invite Players</h2>
                                </div>
                            :
                                ""
                        }
                        
                        <div className="scrum-poker-app-middle-row-player">
                            <div className="scrum-poker-app-side-row-players">
                                {    
                                    leftUser.map( (cardValue,index) => ( 
                                        <div className="scrum-poker-app-player-full-card-container" key={index}>
                                            <h2 className="scrum-poker-app-player-name">{cardValue.user}</h2>
                                            <div className={`scrum-poker-app-player-card ${cardValue.selected ? "scrum-poker-app-player-card-selected" : "otherclass"} ${revealCards ? "scrum-poker-app-player-card-flipped" :"otherclass"}`} >
                                                <div className="scrum-poker-app-player-inner-card">
                                                    {
                                                        revealCards ? <label > {cardValue?.vote}</label>: <img src={cardLogo} className="scrum-poker-app-card-logo"/>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                } 
                            </div>

                            <div>
                                <div className="scrum-poker-app-top-row-players">
                                    {    
                                        toptUser.map( (cardValue, index) => ( 
                                            <div className="scrum-poker-app-player-full-card-container" key={index}>
                                                <h2 className="scrum-poker-app-player-name">{cardValue.user}</h2>
                                                <div className={`scrum-poker-app-player-card ${cardValue.selected ? "scrum-poker-app-player-card-selected" : "otherclass"} ${revealCards ? "scrum-poker-app-player-card-flipped" :"otherclass"}`}>
                                                    <div className="scrum-poker-app-player-inner-card">
                                                        {
                                                            revealCards ? <label > {cardValue?.vote}</label>: <img src={cardLogo} className="scrum-poker-app-card-logo"/>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    } 
                                </div>

                                <div className="scrum-poker-app-desk">
                                    <button className="scrum-poker-app-reveal-cards-btn" onClick={handleRevealCards}>{revealCards ? "Vote again" : "Reveal cards"}</button>
                                </div>

                                <div className="scrum-poker-app-bottom-row-players">
                                    {    
                                        bottomUser.map( (cardValue, index) => ( 
                                            <div className="scrum-poker-app-player-full-card-container" key={index}>
                                                <h2 className="scrum-poker-app-player-name">{cardValue.user}</h2>
                                                <div className={`scrum-poker-app-player-card ${cardValue.selected ? "scrum-poker-app-player-card-selected" : "otherclass"} ${revealCards ? "scrum-poker-app-player-card-flipped" :"otherclass"}`}>
                                                    <div className="scrum-poker-app-player-inner-card">
                                                        {
                                                            revealCards ? <label > {cardValue?.vote}</label>: <img src={cardLogo} className="scrum-poker-app-card-logo"/>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    } 
                                </div>

                            </div>
                           

                            <div className="scrum-poker-app-side-row-players">
                                {    
                                    rightUser.map( (cardValue, index) => ( 
                                        <div className="scrum-poker-app-player-full-card-container" key={index}>
                                            <h2 className="scrum-poker-app-player-name">{cardValue.user}</h2>
                                            <div className={`scrum-poker-app-player-card ${cardValue.selected ? "scrum-poker-app-player-card-selected" : "otherclass"} ${revealCards ? "scrum-poker-app-player-card-flipped" :"otherclass"}`}>
                                                <div className="scrum-poker-app-player-inner-card">
                                                    {
                                                        revealCards ? <label > {cardValue?.vote}</label>: <img src={cardLogo} className="scrum-poker-app-card-logo"/>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                } 
                            </div>
                        </div>
                        
                    </div>

                    <div className="scrum-poker-app-footer-container">
                        <h2 className="scrum-poker-app-player-pick-cards-text">Choose your card 👇</h2>
                        <div className="scrum-poker-app-player-cards-container">
                            
                            {    
                                userVotingCards.map( (cardValue,index) => ( 
                                    <VotingCard
                                        key = {index}
                                        cardValue={cardValue.value}
                                        id={index}
                                        selected={cardValue.selected}
                                        handleUserVote = {handleUserVote}
                                    />
                                ))
                            }
                        </div>
                    </div>
                    
                </div>
            </div>
            
            {openPlayerInitialInfo && <PlayerInfo gameRoomGuid={gameRoomGuid} onClosePlayerInitialInfo={handleCloseInitialPlayerInfo} />}
            {openPlayerEditInfo &&  <EditPlayerInfo gameRoomGuid={gameRoomGuid} userSessionInfo={userSessionInfo} onClosePlayerEditInfo={handleCloseEditPlayerInfo} />}
            {/* {openRenewSession &&  <RenewSession gameRoomGuid={gameRoomGuid} userSessionInfo={userSessionInfo} onClosePlayerRenewSession={handleClosePlayerRenewSession} />} */}
            {openCopiedModal &&  <CopiedModal onCloseCopiedModal={handleCloseCopiedModal} />}
        </React.Fragment>
    )

}

export {ScrumPokerApp};