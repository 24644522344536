import React from "react";
import "./../styles/navbar.css";

import { useNavigate } from "react-router-dom";

import logo from "./../imgs/Brand/logo-main.webp";

function Navbar () {

    const navigate = useNavigate();

    return (
        <nav className="navbar-main-container">
            <div className="navbar-inner-container">
                <div className="navbar-lef-content">
                    <img src = {logo} alt="agilexcellence main logo" className="navbar-company-logo" onClick ={() => navigate("/")}/>
                </div>
                <div className="navbar-right-content">
                    <button className="navbar-start-new-game-btn"  onClick ={() => navigate("/new-game")}>Start new game</button>
                </div>
            </div>

        </nav>
    );
}

export {Navbar};