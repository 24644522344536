import React from "react";
import "./../styles/home.css";

import { Navbar } from "./../components/Navbar";

function Home () {

    return (
        <section>
            <Navbar/>
        </section>
    );
}

export {Home};