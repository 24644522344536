import React,{useEffect,useCallback} from "react";
import  {createPortal}  from "react-dom";
import "../styles/playerInfo.css";

import {getGraphQl} from "./../hooks/ApiCalls";
import  useGameUserDetails from "./../hooks/useGameUserDetails";

import closeIcon from "./../imgs/ActionsIcons/close.svg";

function PlayerInfo ({gameRoomGuid, onClosePlayerInitialInfo}) {

    const {gameUserDetails, setgameUserDetails} = useGameUserDetails();

    const [values, setValues] = React.useState({
        gameRoomGuid: gameRoomGuid,
        name:"",
      });
    
      function handleChange(evt) {
        const { target } = evt;
        const { name, value } = target;
    
        const newValues = {
          ...values,
          [name]: value,
        };
        setValues(newValues);
      }
      
      const handleSubmit = async (evt) => {
        evt.preventDefault();
        const UserSessionDetail = await fetch('https://back.doraglobaltrade.com/userGameSession',
        {
          method: 'POST',
          headers: {
            'Content-Type':'application/json'
          },
          body: JSON.stringify(values)
        }).then(data => data.json())
        
        setgameUserDetails(UserSessionDetail);
        onClosePlayerInitialInfo();
      }

    return createPortal(
          <div className="ModalBackground-player-info">
              <div className="add-course-form-main-container" onSubmit={handleSubmit}>
                  <div className="add-course-form-container">
                    <h1 className="add-course-form-title">Enter your player information</h1>
                    <form className="add-course-form-inner-container">
                        <div className="add-course-form-items">
                            <input className="add-course-form-item" name= "name" type="text" placeholder="Write your display name" value={values.name} onChange={handleChange} required />
                        </div>
                        <div className="add-course-btns">
                            <button className="add-course-btn-create" type="submit"> Save </button>
                        </div>
                    </form>
                  </div>
              </div>
            </div>
            ,
            document.getElementById("modal")
    );
}

export {PlayerInfo};